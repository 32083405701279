<template>
	<div class="view">
		<div class="main text-center">
			<b-row class="">
				<b-col class="title">비밀번호 재설정하기</b-col>
			</b-row>

			<b-form-group id="email">
				<b-row>
					<b-col>
						<b-form-input id="email" class="input" v-model="email" debounce="300" placeholder="회원가입 시 등록한 이메일주소를 입력해주세요" v-if="!email_submitted && !email_submitting" aria-describedby="email-feedback"></b-form-input>
						<b-form-input class="input" disabled v-model="email" v-if="email_submitted || email_submitting"></b-form-input>
					</b-col>
				</b-row>
				<b-row class="">
					<b-col>
						<b-form-invalid-feedback id="email-feedback" :state="(email.length === 0 || email_validation) && email_exists"> {{ email_feedback }} </b-form-invalid-feedback>
					</b-col>
				</b-row>
			</b-form-group>

			<b-row v-if="email_submitted" class="passwordReset--resulttext">
				<b-col>
					<b-card> 입력해주신 이메일 주소로 비밀번호 재설정 링크를 보냈습니다. 메일에 적힌 링크로 들어가 새 비밀번호를 설정하세요. </b-card>
				</b-col>
			</b-row>

			<b-row align-h="center">
				<b-col class="text-center">
					<b-button block v-if="(!email_validation || email_submitted) && !email_submitting" disabled>재설정 링크 보내기</b-button>
					<b-button block v-if="email_submitting && !email_submitted" disabled><b-spinner></b-spinner></b-button>
					<b-button block class="orange" v-if="email_validation && !email_submitted && !email_submitting" type="submit" v-on:click="makePasswordReset"> 재설정 링크 보내기 </b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
export default {
	name: 'PasswordReset',
	metaInfo: {
		// 페이지 제목 설정
		title: '비밀번호 재설정',
		titleTemplate: '%s | NOLGONG SCHOOL',
		meta: [
			// SEO 설정
			{
				name: 'description',
				content:
					'학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소> | ‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'description',
			},
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
		],
	},
	data() {
		return {
			// 회원가입 1단계 데이터
			email: '',
			email_submitted: false,
			email_feedback: '', // 이메일 경고 메세지 variation
			email_submitting: false,
			email_exists: true, // 서버에서 존재 여부 확인하기 전까지는 존재한다고 가정
		};
	},
	computed: {
		// 이메일 형식 검사 처리
		email_validation() {
			this.email_exists = true; // 이메일 수정해서 유효성 검사할 때에는 존재한다고 가정
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (re.test(this.email)) {
				return true;
			} else {
				this.email_feedback = '이메일의 형태로 입력해주세요.';
				return false;
			}
		},
	},
	methods: {
		// 회원가입 요청
		makePasswordReset() {
			this.email_submitting = true;
			// actions 메소드 호출
			this.$store
				.dispatch('PASSWORD_RESET', {
					email: this.email,
				})
				.then((res) => {
					// 서버 응답이 정상일 경우
					if (this.$store.state.serverStatus === 204) {
						this.email_submitted = true;
						this.email_submitting = false;
					}
					// 이메일 부재로 요청 실패
					if (this.$store.state.serverStatus === 400) {
						this.email_submitting = false;
						this.email_exists = false;
						this.email_feedback = '이메일 주소를 다시 확인해주세요. 이메일 주소는 대소문자를 구분합니다.';
					}
				});
		},
	},
	components: {},
};
</script>

<style scoped>
.main {
	padding-top: 30vh;
}

.title {
	font-size: 1.2em;
	font-weight: bold;
	margin-bottom: 2rem;
}
.card {
	margin-bottom: 1em;
}
.card,
.invalid-feedback {
	font-size: 90%;
}
.btn {
	margin: auto;
}
@media (min-width: 992px) {
	.main {
		margin: auto;
		width: 50%;
	}
}
</style>
